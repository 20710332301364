/*
 * @Author: your name
 * @Date: 2022-01-17 10:39:48
 * @LastEditTime: 2022-01-27 21:17:06
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /web/src/store/index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginShow: false,
    loginType: 0,
    user: {
      id: null,
      phone: "",
      headPic: "",
      nickName: "",
    }
  },
  mutations: {
    showLogin(state, type) {
      state.loginShow = true;
      state.loginType = type;
    },
    hideLogin(state) {
      state.loginType = 0;
      state.loginShow = false;
    },
    updateUser(state, user) {
      if (user === null) {
        state.user = {
          id: null,
          phone: "",
          headPic: "",
          nickName: "",
        }
      } else {
        state.user = {
          ...state.user,
          ...user
        };
      }

    }
  },
  actions: {
    showLogin(context, type) {
      context.commit('showLogin', type)
    },
    hideLogin(context) {
      context.commit('hideLogin')
    },
    updateUser(context, user) {
      context.commit('updateUser', user)
    }
  },
  modules: {
  }
})
