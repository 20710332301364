<!--
 * @Author: your name
 * @Date: 2022-01-17 10:39:48
 * @LastEditTime: 2022-01-27 20:58:11
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /web/src/App.vue
-->
<template>
  <div id="app">
    <router-view />

    <Login />
  </div>
</template>
<script>
import Login from "@/components/Login";
export default {
  components: {
    Login,
  },
};
</script>


<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  background: #f8f8f8 !important;
}
#app {
  font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 16px;
}
ul,
li {
  list-style: none;
}
.w-1220 {
  max-width: 1220px;
  padding: 0 10px;
  margin: 0 auto;
}
.btn-main {
  display: inline-block;
  height: 40px;
  background: #c14038;
  border-radius: 20px;
  color: #fff;
  line-height: 40px;
  padding: 0 18px;
  font-size: 16px;
  cursor: pointer;
}
.btn-main-small {
  display: inline-block;
  height: 30px;
  background: #c14038;
  border-radius: 20px;
  color: #fff;
  line-height: 30px;
  padding: 0 18px;
  font-size: 14px;
  cursor: pointer;
}
.btn-main-small-outline {
  display: inline-block;
  height: 30px;
  background: #fff;
  border-radius: 20px;
  color: #c14038;
  line-height: 28px;
  padding: 0 18px;
  font-size: 14px;
  border: 1px solid #c14038;
  cursor: pointer;
}
</style>
