<template>
  <div class="course-list">
    <div
      v-for="(item, i) in list"
      :key="i"
      class="card"
      @click="handleClick(item)"
    >
      <div
        class="cover"
        :style="{ backgroundImage: `url(${item.coverUrl})` }"
      ></div>
      <div class="title">{{ item.title }}</div>
      <div class="desc">{{ item.taskNum }}</div>
      <!-- 共{{ item.lessonNum }}课节 -->
    </div>
  </div>
</template>

<script>
import {getCookie} from '@/utils';

export default {
  props: ["list"],
  methods: {
    handleClick(item) {
      console.log(item);
      let routeUrl;
      if(!this.$store.state.user.sessionKey || !getCookie('Sessionkey')) {
        return this.$store.dispatch("showLogin", 0);
      }
      if (item.courseType === 1) {
        routeUrl = this.$router.resolve({
          name: "VideoCourse",
          query: {
            id: item.id,
          },
        });
      } else if (item.courseType === 2) {
        routeUrl = this.$router.resolve({
          name: "AudioCourse",
          query: {
            id: item.id,
          },
        });
      } else if (item.courseType === 3) {
        routeUrl = this.$router.resolve({
          name: "PdfCourse",
          query: {
            id: item.id,
          },
        });
      } else if (item.courseType === 4) {
        routeUrl = this.$router.resolve({
          name: "PdfCourse",
          query: {
            id: item.id,
          },
        });
      } else {
        alert("课程配置错误，请联系客服");
      }
      if (routeUrl) {
        window.open(routeUrl.href, "_blank");
      }
    },
  },
};
</script>

<style>
.course-list {
  display: grid;
  grid-template-columns: repeat(4, calc((100% - 16px * 3) / 4));
  grid-column-gap: 16px;
  column-gap: 16px;
}
.course-list .card {
  padding-bottom: 10px;
}
.course-list .card .cover {
  padding-top: 57.83%;
  background-color: #fff;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.course-list .card .title {
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
  padding: 0 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.course-list .card .desc {
  font-size: 14px;
  color: #666;
  margin-top: 8px;
  padding: 0 12px;
}

@media screen and (max-width: 798px) {
  .course-list {
    grid-template-columns: repeat(2, 49%);
    grid-column-gap: 2%;
    column-gap: 2%;
  }
}
</style>