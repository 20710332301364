/*
 * @Author: your name
 * @Date: 2022-03-01 21:34:08
 * @LastEditTime: 2022-03-01 21:41:44
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /web/src/utils/lang.js
 */
export const  COURSE_CATE = {
  STANDARD: '标准化课堂',
  FAMOUS_TEACHER: '名师讲堂',
  COMMON_KNOWLEDGE: '公共知识',
  SOURSES: '教辅资源'
}