<template>
  <div>
    <Header />
    <div class="w-1220">
      <div style="display: flex;margin-top: 20px;">
        <div style="width: 200px">
          <div class="course-poster" :style="{'background-image':`url(${coverUrl})`}" />
        </div>
        <div class="course-info">
          <div class="title">{{ title }}</div>
          <div class="tags"><span v-for="tag in tags" :key="tag">{{tag}}</span> </div>
        </div>
      </div>
      <div class="audio-course">
        <div class="course-container">
          <div class="course-content">
            <div class="item-h">
              <div class="title">章节名称</div>
              <!-- <div class="duration">时长</div> -->
            </div>
            <div>
              <template
                v-for="(item) in list"
              >
                <div :key="item.id" v-if="item.children && item.children.length>0">
                  <div
                    :key="item.id"
                    :class="['item', 'sub-item']"
                    @click="item.collpased = !item.collpased"
                  >
                    <div class="title">
                      <Icon :class="{'chapter-subarrow-forward':item.collpased}" class="chapter-subarrow" type="ios-arrow-down" />
                      {{ item.name }}
                    </div>
                    <div class="duration">{{ item.durationStr }}</div>
                  </div>
                  <div 
                    class="child-list" 
                    :class="{'child-list-show': !item.collpased}"
                  >
                    <div
                      v-for="(child) in item.children"
                      :key="item.id + '-' +child.id"
                      :class="['item', 'child-item', child.id === curId ? 'active' : '']"
                      @click="handleChange(child)"
                    >
                      <div
                        v-if="curId===child.id && playing"
                        class="audio-playing"
                      >
                        <div class="ani-content">
                          <div class="p1"></div>
                          <div class="p2"></div>
                          <div class="p3"></div>
                          <div class="p4"></div>
                        </div>
                      </div>
                      <div class="title">
                        {{ child.name }}
                      </div>
                      <div class="duration">查看资源</div>
                    </div>
                  </div>
                </div>

                 <div
                    v-else
                    :key="item.id"
                    :class="['item','child-item', item.id === curId ? 'active' : '']"
                    @click="handleChange(item)"
                  >
                    <div
                      v-if="curId===item.id && playing"
                      class="audio-playing"
                    >
                      <div class="ani-content">
                        <div class="p1"></div>
                        <div class="p2"></div>
                        <div class="p3"></div>
                        <div class="p4"></div>
                      </div>
                    </div>
                    <div class="title">
                      {{ item.name }}
                    </div>
                    <div class="duration">查看资源</div>
                  </div>
                
              </template>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// import Mockjs from "mockjs";
import Header from "@/components/layouts/Header";
import Footer from "@/components/layouts/Footer";
import {viewPdf} from '@/utils';

const SOURCE_TYPE = 3;
const PPT_TYPE = 4;

export default {
  name: "PdfCourse",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      id: "",
      courseType: 0,
      curId: -1,
      list: [],
      coverUrl: '',
      title: '',
      tags: []
    };
  },
  created() {
    // this.getList();
    this.id = this.$route.query.id;
    if (!this.id) {
      return alert("未找到课程");
    }
    this.getCourseInfo();
  },
  methods: {
    getCourseInfo() {
      this.$axios
        .get("/v1/course/getCourseInfo", {
          params: { courseId: this.id },
        })
        .then((res) => {
          if (res.code === 0) {
            const courseInfo = res.data;
            this.title = courseInfo.courseName;
            this.coverUrl = courseInfo.coverUrl;
            this.tags = courseInfo.tagList;
            this.courseType = courseInfo.courseType;
            let first = null;
            let index = -1;
            const getChildChapter = (chapter) => {
              index++;
              const firstSource = chapter.coursewareList.find(
                (item) => [SOURCE_TYPE, PPT_TYPE].includes(item.resourceType)
              );
              const chapterItem = {
                index,
                id: chapter.chapterId,
                name: chapter.chapterName,
                resourceInfo: firstSource,
                children: [],
              };
              return chapterItem;
            };
            this.list = courseInfo.list.reduce((chapterList, chapter) => {
              let obj;
              if (chapter.list.length > 0) {
                obj = {
                  id: chapter.chapterId,
                  collpased: false,
                  name: chapter.chapterName,
                  children: chapter.list.map(getChildChapter),
                };
              } else {
                obj = getChildChapter(chapter);
              }
              chapterList.push(obj);
              console.log(chapterList);
              return chapterList;
            }, []);
            if (first) this.handleChange(first);
          } else {
            this.$Message.error(res.msg);
          }
        });
    },
    handleChange(courseItem) {
      console.log(courseItem)
      if (!courseItem.resourceInfo || !courseItem.resourceInfo.resourceUrl) {
        this.$Message.error("该课程未配置资料，请联系管理员");
        return;
      }
      if(+courseItem.resourceInfo.resourceType === 3) viewPdf(courseItem.resourceInfo.resourceUrl)
      else window.open('https://view.officeapps.live.com/op/view.aspx?src=' + courseItem.resourceInfo.resourceUrl);
    },
  },
};
</script>

<style scoped>
.course-poster{
  width: 200px; 
  height: 132px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
}
.course-info{
  padding: 36px 0 0 20px;
}

.course-info .title{
  font-size: 18px;
  margin-bottom: 12px;
}
.course-info .tags span{
  padding: 0 10px;
  border-radius: 3px;
  margin-right: 10px;
  text-align: center;
  background-color: rgba(193, 64, 56, 0.1);
  color: #c14038;
}

.audio-course {
  display: flex;
  padding-top: 20px;
  padding-bottom: 40px;
}
.slide-right {
  padding-left: 11.5%;
  width: 35%;
  flex-shrink: 0;
  border-left: 1px solid #e7e7e7;
}
.slide-right .desc {
  margin-top: 10px;
  color: #666;
  font-size: 14px;
}
.course-container {
  width: 100%;
  padding-right: 50px;
}
.player .title {
  height: 50px;
  padding-right: 130px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.play-info {
  padding-right: 100px;
}
.play-info .duration {
  float: right;
  margin-right: -100px;
  line-height: 38px;
  font-size: 14px;
  color: #666;
}
.progress-container {
  padding-top: 17px;
}
.progress-wrap {
  width: 100%;
  height: 6px;
  background-color: #e8eaec;
  border-radius: 3px;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}

.progress-wrap .pro-bar {
  height: 6px;
  background: #c14038;
  border-radius: 3px;
  position: absolute;
  transition: width 0.1s;
}

.progress-wrap .pro-bar .pro-control {
  position: absolute;
  right: -4px;
  top: -3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #fff;
  background: #c14038;
}

.audio-container {
  display: flex;
}
.audio-container .audio-cover {
  margin: 0 auto;
  width: 144px;
  height: 144px;
  border-radius: 3px;
  background-size: cover;
  flex-shrink: 0;
}

.audio-container .player {
  position: relative;
  width: 100%;
  padding: 30px 16px;
}

.play-control {
  position: absolute;
  right: 17px;
  top: 25px;
  display: flex;
  align-items: center;
}
.play-control .btn {
  cursor: pointer;
  opacity: 1;
}
.play-control .btn:not(.disabled):active {
  transform: translateY(1px) scale(0.98);
}
.play-control .btn:hover {
  opacity: 0.9;
}
.play-control .btn-center {
  width: 37px;
  height: 36px;
  margin-left: 10px;
  margin-right: 10px;
}
.play-control .btn.disabled {
  opacity: 0.5 !important;
}
.play-control .btn-pre {
  width: 15px;
}
.play-control .btn-next {
  width: 15px;
}

.course-content {
  font-size: 14px;
  margin: 0 0 30px;
}
.course-content .item {
  display: flex;
  align-items: center;
  height: 54px;
  padding: 0 10px;
}
.course-content .item:not(.active) {
  cursor: pointer;
}
.course-content .item:hover {
  background-color: rgba(137, 149, 171, 0.1);
}

.course-content .item.active {
  background: rgba(137, 149, 171, 0.1);
  color: #c14038;
}
.item-h {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 54px;
  color: #666;
  cursor: default !important;
}
.item {
  position: relative;
  height: 54px;
}
.item .audio-playing {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  /* transform: translateY(200%); */
}

.item .audio-playing .ani-content {
  position: absolute;
  left: 8px;
  bottom: 16px;
  width: 16px;
}

.item .audio-playing .ani-content div {
  display: inline-block;
  background: #c14038;
  width: 2px;
  margin-left: 1px;
  transform-origin: bottom;
}
@keyframes living {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(100%);
  }
}
.item .audio-playing .ani-content div.p1 {
  height: 7px;
  -webkit-animation: 0.8s 0.1s living linear infinite backwards normal;
  animation: 0.8s 0.1s living linear infinite backwards normal;
  -webkit-animation-delay: -1.1s;
}
.item .audio-playing .ani-content div.p2 {
  height: 10px;
  -webkit-animation: 0.8s 0.3s living linear infinite backwards normal;
  animation: 0.8s 0.3s living linear infinite backwards normal;
  -webkit-animation-delay: -1.3s;
}
.item .audio-playing .ani-content div.p3 {
  height: 12px;
  -webkit-animation: 0.8s 0.6s living linear infinite backwards normal;
  animation: 0.8s 0.6s living linear infinite backwards normal;
  -webkit-animation-delay: -1.6s;
}
.item .audio-playing .ani-content div.p4 {
  height: 9px;
  -webkit-animation: 0.8s 0.3s living linear infinite backwards normal;
  animation: 0.8s 0.3s living linear infinite backwards normal;
  -webkit-animation-delay: -1.3s;
}

.course-content .item .title {
  width: 100%;
  /* padding-left: 40px; */
}
.course-content .item .title .no {
  float: left;
  /* display: inline-block; */
  width: 40px;
  margin-left: -40px;
  text-align: center;
}
.course-content .item .duration {
  width: 60px;
  flex-shrink: 0;
  text-align: right;
  color: #c14038;
}

.course-content .item.child-item{
  padding-left: 28px;
}
.sub-item{
  color: #666;
}
.child-list{
  max-height: 0;
  overflow: hidden;
  transition: all .2s;
}
.child-list-show {
  max-height: 2000px;
}
.chapter-subarrow{
  transition: all 0.2s;
}
.chapter-subarrow-forward{
  transform: rotate(-90deg);
}
@media screen and (max-width: 798px) {
  .slide-right {
    display: none;
  }
  .course-container {
    padding-right: 0;
  }
  .audio-container {
    display: block;
  }
}
</style>
