<template>
  <div class="banner">
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide
        v-for="banner in banners"
        :key="banner.id"
        :style="{
          backgroundImage: `url(${banner.pic})`,
        }"
      >
        <div
          v-if="banner.link"
          @click.stop="bannerClick(banner)"
          style="
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
          "
        ></div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import Vue from "vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.min.css";
Vue.use(VueAwesomeSwiper /* { default options with global component } */);

export default {
  data() {
    return {
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 3000, //自动切换的时间间隔，单位ms
          stopOnLastSlide: false, //当切换到最后一个slide时停止自动切换
          disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay。
          reverseDirection: false, //开启反向自动轮播。
          waitForTransition: true, //等待过渡完毕。自动切换会在slide过渡完毕后才开始计时。
        },
        delay: 1000,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      banners: [],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
    this.getBanners();
  },
  methods: {
    getBanners() {
      this.$axios
        .get("dd/getRPData", {
          params: {
            type: 2,
            page: 1,
            status: 1,
          },
        })
        .then((res) => {
          this.banners = res.data.map((item) => {
            return {
              id: item.posterId,
              link: item.url,
              pic: item.ddImgUrl,
            };
          });
        });
    },
    bannerClick(banner) {
      window.open(banner.link);
    },
  },
};
</script>

<style>
/* .banner-img{
  width: 100%;
  padding-top: 35.25%;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
} */
.banner {
  overflow: hidden;
}
.swiper-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.swiper-slide {
  width: 100%;
  padding-top: 35.25%;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.banner .swiper-pagination-bullet {
  opacity: 0.5;
  background: rgb(196, 188, 188);
}
.banner .swiper-pagination-bullet-active {
  opacity: 1;
  background: #c14038;
}
</style>