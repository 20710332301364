<!--
 * @Author: your name
 * @Date: 2022-01-24 21:00:15
 * @LastEditTime: 2022-04-19 21:52:02
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /web/src/components/Login.vue
-->
<template>
  <Modal class="login-modal" v-model="$store.state.loginShow" width="360">
    <p slot="header" style="text-align: center">
      <span>{{ isLoginType ? "登录" : "注册" }}账号</span>
    </p>
    <div class="container">
      <Form :modal="form" :label-width="60">
        <FormItem label="手机号" prop="phone">
          <Input style="width: 240px" v-model="form.phone" />
        </FormItem>
        <template v-if="isLoginType">
          <FormItem v-if="isSms" label="验证码" prop="sms">
            <Input
              style="width: 120px; margin-right: 10px"
              v-model="form.sms"
            />
            <Button
              @click="sendSms"
              type="primary"
              style="width: 110px"
              v-if="time === 0"
              >发送验证码</Button
            >
            <Button disabled style="width: 110px" v-else>{{
              `${time}s后重发`
            }}</Button>
          </FormItem>
          <FormItem v-else label="密码" prop="password">
            <Input
              type="password"
              password
              style="width: 240px"
              v-model="form.password"
            />
          </FormItem>
        </template>
        <template v-else>
          <FormItem label="密码" prop="password">
            <Input
              type="password"
              style="width: 240px"
              v-model="form.password"
            />
          </FormItem>
          <FormItem label="验证码" prop="sms">
            <Input
              style="width: 120px; margin-right: 10px"
              v-model="form.sms"
            />
            <Button
              v-if="time === 0"
              type="primary"
              style="width: 110px"
              @click="sendSms"
              >发送验证码</Button
            >
            <Button disabled style="width: 110px" v-else>{{
              `${time}s后重发`
            }}</Button>
          </FormItem>
        </template>
      </Form>
    </div>
    <div v-if="isLoginType" class="type" @click="changeType">
      <Icon type="ios-swap" /> 切换至{{ isSms ? "密码" : "短信验证码" }}登录
    </div>
    <div slot="footer">
      <Button @click="cancel">取消</Button>
      <Button type="primary" @click="login">{{
        isLoginType ? "登录" : "注册"
      }}</Button>
    </div>
  </Modal>
</template>
<script>
import {setCookie} from '@/utils';

export default {
  data() {
    return {
      time: 0,
      isSms: false,
      form: {
        phone: "",
        sms: "",
        password: "",
      },
    };
  },
  computed: {
    isLoginType() {
      return this.$store.state.loginType === 0;
    },
  },
  created() {
    const localUser = localStorage.getItem("_USER");
    if (localUser) {
      try {
        this.$store.dispatch("updateUser", JSON.parse(localUser));
      } catch (e) {
        console.error(e);
      }
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
  methods: {
    login() {
      if (!/^1[3-9]\d{9}/.test(this.form.phone)) {
        return this.$Message.error("手机号格式不正确");
      }
      if (this.isLoginType) {
        this.$axios
          .post("/user/login", {
            loginType: this.isSms ? 1 : 2,
            smsCode: this.form.sms,
            phone: this.form.phone,
            pwd: this.form.password,
          })
          .then((res) => {
            // 获取和设置token
            console.log(res);
            if (res.code === 0) {
              this.$Message.success("登录成功");
              this.$store.dispatch("updateUser", res.data);
              setCookie('Sessionkey', res.data.sessionKey);
              setCookie('SessionKey', res.data.sessionKey);
              localStorage.setItem(
                "_USER",
                JSON.stringify(this.$store.state.user)
              );
              this.cancel();
            } else {
              this.$Message.error(res.msg);
            }
          });
      } else {
        this.$axios
          .post("/user/register", {
            roleType: 1,
            smsCode: this.form.sms,
            phone: this.form.phone,
            pwd: this.form.password,
          })
          .then((res) => {
            // 获取和设置token
            console.log(res);
            if (res.code === 0) {
              this.$Message.success("注册成功，请登录");
              let phone = this.form.phone;
              this.cancel();
              this.$store.dispatch("showLogin", 0);
              this.form.phone = phone;
            } else {
              this.$Message.info(res.msg);
            }
          });
      }
    },
    sendSms() {
      if (!/^1[3-9]\d{9}/.test(this.form.phone)) {
        return this.$Message.error("手机号格式不正确");
      }
      const run = () => {
        this.timer = setTimeout(() => {
          this.time--;
          if (this.time > 0) {
            run();
          } else {
            this.time = 0;
            this.timer = null;
          }
        }, 1000);
      };
      this.$axios.post('/sms/get/sms_code', null, {
        params: {
          phone: this.form.phone,
          type: this.isLoginType ? 2: 1
        }
      }).then(() => {
        this.time = 60;
        run();
      }).catch(() => {
        this.$$Message.error('系统错误')
      })
    },
    checkSms() {
      // 校验验证码
    },
    cancel() {
      this.$store.dispatch("hideLogin");
      this.form = {
        phone: "",
        sms: "",
        password: "",
      };
      this.time = 0;
    },
    changeType() {
      this.isSms = !this.isSms;
    },
  },
};
</script>

<style>
.login-modal .container {
  padding-top: 20px;
}
.login-modal .type {
  text-align: right;
  cursor: pointer;
  color: #666;
  font-size: 14px;
  padding-right: 10px;
  user-select: none;
}
</style>

