<!--
 * @Author: your name
 * @Date: 2022-02-14 22:55:22
 * @LastEditTime: 2022-02-24 20:56:49
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /web/src/views/Courses/index.vue
-->
<template>
  <div>
    <Header />
    <div class="w-1220">
      <div class="courses-container">
        <course-filter ref="courseFilter" @courseChange="handleCourseChange" />
        <div style="margin-top: 30px"></div>
        <course-list ref="courseList" :list="list" />
        <div v-if="total>list.length" class="load-more">
          <Button type="primary" @click="handleMore" circle>加载更多</Button>
        </div>
        <div class="empty-wrap" v-if="list.length === 0">
          <img
            src="../../assets/empty.png"
            style="width: 70%; max-width: 340px"
          />
          <div style="color: #6d6d80; font-size: 14px; padding-left: 20px">
            内容还没准备好 ~
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/layouts/Header";
import Footer from "@/components/layouts/Footer";
import CourseFilter from "./components/CourseFilter";
import CourseList from "@/components/CourseList";

export default {
  name: "Courses",
  components: {
    Header,
    Footer,
    CourseFilter,
    CourseList,
  },
  data() {
    return {
      list: [],
      total: 0,
    };
  },
  created() {},
  methods: {
    handleCourseChange(list, total, page) {
      this.list = page === 1 ? list: this.list.concat(list);
      this.total = total;
    },
    handleMore() {
      this.$refs.courseFilter.getMore();
    }
  },
};
</script>

<style>
.courses-container {
  margin: 32px 0;
  min-height: 500px;
}
.empty-wrap {
  text-align: center;
}
.load-more{
  text-align: center;
  padding: 20px 0;
}
</style>
