<template>
  <div class="course-filter">
    <div class="cate-line">
      <div class="label">内容分类：</div>
      <div class="type-list">
        <div
          :class="['type-item', type.value === curType ? 'active' : '']"
          v-for="type in optionsType"
          :key="type.value"
          @click="handleTypeChange(type)"
        >
          {{ type.label }}
        </div>
      </div>
    </div>
    <div v-if="curType !== ''" class="cate-line">
      <div class="label">{{ subOptionName }}：</div>
      <div class="type-list">
        <div :class="['type-item', subType === 'my' ? 'active' : '']" @click="handleMyCourse">我的</div>
        <div
          :class="['type-item', type.value === subType ? 'active' : '']"
          v-for="type in subOptions"
          :key="type.value"
          @click="handleChildTypeChange(type.value)"
        >
          {{ type.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {COURSE_CATE} from '@/utils/lang';

export default {
  name: "CourseFilter",
  components: {},
  data() {
    return {
      curType: 1,
      subType: "-1",
      optionsType: [
        // {
        //   value: "",
        //   label: "全部",
        // },
        {
          value: 1,
          label: COURSE_CATE.STANDARD,
          optionName: "标签",
        },
        {
          value: 2,
          label: COURSE_CATE.FAMOUS_TEACHER,
          optionName: "标签",
        },
        {
          value: 3,
          label: COURSE_CATE.COMMON_KNOWLEDGE,
          optionName: "标签",
        },
        {
          value: 4,
          label: COURSE_CATE.SOURSES,
          optionName: "标签",
        },
      ],
      subOptionName: "标签",
      page: 1,
      subOptions: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$axios
        .get("/v1/app/list", {
          params: {
            courseType: this.curType,
            code: this.subType,
            page: this.page,
            pageSize: 20,
          },
        })
        .then((res) => {
          const tags = res.data.tagList;
          this.subOptions = tags.map((item) => {
            return {
              value: item.code,
              label: item.name,
            };
          });
          this.$emit("courseChange", res.data.listData.list || [], res.data.listData.total, this.page);
        }).catch(() => {
          console.error('系统错误');
          this.subOptions = [{
            value: "",
            label: "全部",
          }];
          this.$emit("courseChange", [], 0, 1);
        });
    },
    getMore() {
      this.page ++;
      this.getList()
    },
    handleTypeChange(item) {
      this.curType = item.value;
      this.subType = "-1";
      this.subOptionName = item.optionName;
      this.page = 1;
      // this.getSubOptions();
      this.getList();
    },
    handleChildTypeChange(val) {
      this.page = 1;
      this.subType = val;
      this.getList();
    },
    handleMyCourse() {
      this.page = 1;
      this.subType = 'my';
      this.$axios.get('/v1/course/my_course_list', {
        params: {
          courseType: this.curType,
          page: this.page,
          pageSize: 20,
        }
      }).then(res => {
        this.$emit("courseChange", res.data.list || [], res.data.total, this.page);
      })
    }
  },
};
</script>

<style scoped>
.course-filter {
}
.cate-line {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 24px;
}

.cate-line .label {
  color: #999;
  width: 80px;
  flex-shrink: 0;
}

.course-filter .type-list {
  display: flex;
  align-items: center;
  overflow-x: auto;
}

.course-filter .type-list .type-item {
  flex-shrink: 0;
  padding: 0 10px;
  border-radius: 3px;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  background-color: transparent;
}
.course-filter .type-list .type-item:hover {
  color: #c14038;
}
.course-filter .type-list .type-item.active {
  background-color: rgba(193, 64, 56, 0.1);
  color: #c14038;
}
</style>
