/*
 * @Author: your name
 * @Date: 2022-02-13 16:50:29
 * @LastEditTime: 2022-03-01 22:19:11
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /web/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/axios'
import './plugins/iview.js'

Vue.config.productionTip = false
import {getCookie} from '@/utils';

router.beforeEach((to, from, next) => {
  if(to.meta.widthLogin) {
    if(!getCookie('Sessionkey')) {
      alert('请先登录');
      return next('/')
    }
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')