/*
 * @Author: your name
 * @Date: 2022-01-17 10:39:48
 * @LastEditTime: 2022-01-27 21:09:14
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /web/src/plugins/iview.js
 */
import Vue from 'vue'
import { Button, Icon, Slider, Modal, Form, FormItem, Input, Message, Dropdown, DropdownMenu, DropdownItem } from 'view-design'
import '../theme.less'

Vue.component('Button', Button)
Vue.component('Icon', Icon)
Vue.component('Slider', Slider)
Vue.component('Modal', Modal)
Vue.component('Form', Form)
Vue.component('FormItem', FormItem)
Vue.component('Input', Input)
Vue.component('Dropdown', Dropdown)
Vue.component('DropdownMenu', DropdownMenu)
Vue.component('DropdownItem', DropdownItem)
Vue.prototype.$Message = Message

