<template>
  <div>
    <div class="book-list">
      <div
        v-for="(item, i) in books"
        :key="i"
        @click="handleClick(item)"
        class="card"
      >
        <div
          class="cover"
          :style="{ backgroundImage: `url(${item.cover})` }"
        ></div>
        <div class="title">{{ item.name }}</div>
      </div>
    </div>
    <div v-if="total>books.length" class="load-more">
      <Button type="primary" @click="handleMore" circle>加载更多</Button>
    </div>
  </div>
</template>

<script>
// import device from 'devicejs'
// console.log(device);
export default {
  data() {
    return {
      books: [],
      total: 0,
      page: 1,
    }
  },
  mounted() {
    this.getBooks()
  },
  methods: {
    handleClick(item) {
      window.open(item.link);
    },
    getBooks() {
      this.$axios
        .get("/v1/pc/book/list", {
          params: {
            pageSize: 50,
            status: 1,
            page: this.page
          },
        })
        .then((res) => {
          const books = res.data.list.map((item) => {
            return {
              cover: item.cover,
              name: item.name,
              id: item.id,
              link: item.link,
            };
          })
          this.books = this.books.concat(books);
          this.total = res.data.total;
        });
    },
    handleMore() {
      this.page++;
      this.getBooks();
    }
  },
};
</script>

<style scoped>
.book-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  grid-column-gap: 30px;
  column-gap: 30px;
}
.book-list .card {
  padding-bottom: 32px;
  cursor: pointer;
}
.book-list .card .cover {
  padding-top: 133.33%;
  background-color: #fff;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.book-list .card .title {
  font-weight: bold;
  margin-top: 10px;
  padding: 0 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.load-more{
  text-align: center;
  padding: 20px 0;
}
@media screen and (max-width: 1220px) {
  .book-list {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-column-gap: 2%;
    column-gap: 2%;
  }
}
@media screen and (max-width: 798px) {
  .book-list {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-column-gap: 2%;
    column-gap: 2%;
  }
}
@media screen and (max-width: 420px) {
  .book-list {
    grid-template-columns: repeat(3, 32%);
    grid-column-gap: 2%;
    column-gap: 2%;
  }
}
</style>