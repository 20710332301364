<template>
  <div>
    <Header />
    <div class="home">
      <Banner />

      <div
        v-for="(product, i) in productList"
        :key="i"
        class="w-1220 course-module"
      >
        <div class="btn-main">{{ product.anotherName }}</div>
        <div style="margin-top: 32px">
          <CourseList :list="product.list"/>
        </div>
      </div>

      <div style="text-align: center; padding: 54px">
        <router-link 
          :to="{ name: 'Courses' }"
        >
          <div class="btn-main-small-outline btn-more" style="padding: 0 66px">
            查看全部课程
          </div>
        </router-link>
      </div>

      <div class="w-1220 course-module">
        <div class="btn-main">重点书推荐</div>
        <div style="margin-top: 32px">
          <BookList />
        </div>
      </div>
    </div>
    <AppFloat />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/layouts/Header.vue";
import Footer from "@/components/layouts/Footer.vue";
import Banner from "./components/Banner";
import CourseList from "@/components/CourseList";
import BookList from "./components/BookList";
import AppFloat from "@/components/layouts/AppFloat.vue";
import Mock from "mockjs";

export default {
  name: "Home",
  components: {
    Header,
    Banner,
    CourseList,
    BookList,
    Footer,
    AppFloat,
  },
  data() {
    return {
      audioList: [],
      pdfList: [],
      productList: [],
    };
  },
  created() {
    this.audioList = Mock.mock({
      "list|4": [
        {
          id: 26,
          coverUrl:
            "https://img2.baidu.com/it/u=1262355591,535174430&fm=253&fmt=auto&app=138&f=JPEG?w=499&h=229",
          title: "@ctitle(10,15)",
          taskNum: "共9节课",
          lessonNum: 9,
        },
      ],
    }).list;
    this.pdfList = Mock.mock({
      "list|4": [
        {
          id: 26,
          coverUrl:
            "https://img2.baidu.com/it/u=1262355591,535174430&fm=253&fmt=auto&app=138&f=JPEG?w=499&h=229",
          title: "@ctitle(10,15)",
          taskNum: "共9节课",
          courseType: 3
        },
      ],
    }).list;
    console.log(this.pdfList);

    this.books = [];
    this.getCourses();
  },
  methods: {
    getCourses() {
      this.$axios.get("/v1/app/index").then((res) => {
        this.productList = res.data.product;
      });
    },
  },
};
</script>

<style scoped>
.course-module {
  margin-top: 32px;
}
.btn-more:hover {
  background: #c14038;
  color: #fff;
}
</style>
