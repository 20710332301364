/*
 * @Author: your name
 * @Date: 2022-02-13 16:50:29
 * @LastEditTime: 2022-03-01 21:58:19
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /web/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import About from '../views/About'
import Courses from '../views/Courses'
import AudioCourse from '../views/AudioCourse'
import VideoCourse from '../views/VideoCourse'
import PdfCourse from '../views/PdfCourse'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/courses',
    name: 'Courses',
    component: Courses
  },
  {
    path: '/autio-course',
    name: 'AudioCourse',
    component: AudioCourse,
    meta: {
      widthLogin: true
    }
  },
  {
    path: '/video-course',
    name: 'VideoCourse',
    component: VideoCourse,
    meta: {
      widthLogin: true
    }
  },
  {
    path: '/pdf-course',
    name: 'PdfCourse',
    component: PdfCourse,
    meta: {
      widthLogin: true
    }
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    // return 期望滚动到哪个的位置
    return { x: 0, y: 0 }
  }
})

export default router
