<template>
  <div class="app-float">
    <div class="content">
      <img style="width:26px;margin-bottom:5px;" src="../../assets/phone.png" alt="">
      下载App
    </div>
    <div class="qr-wrap">
      <div class="qr-item">
        <img style="width:100%;height:100%;display:block;" src="../../assets/app_ios.jpeg" alt="">
        <div>苹果手机扫码</div>
      </div>
      <div class="qr-item">
        <img style="width:100%;height:100%;display:block;" src="../../assets/app_android.png" alt="">
        <div>安卓手机扫码</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.app-float{
  position: fixed;
  z-index: 99;
  right: 60px;
  top: 50%;
}
.app-float .content{
  width: 100px;
  height: 106px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #E1E1E1;
  text-align: center;
  cursor: pointer;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 20px rgba(0,0,0,0.2);
}
.qr-wrap {
  display: none;
  position: absolute;
  left: -310px;
  top: 0;
  width: 300px;
  height: 190px;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
}
.qr-item {
  float: left;
  text-align: center;
  width: 140px;
}
.app-float:hover .qr-wrap{
  display: block;
}

@media screen and (max-width: 1000px) {
  .app-float{
    display: none;
  }
}
</style>