<template>
  <div class="video-page">
    <div class="header">
      <div class="course-title">
        <!-- <img
          src="../../../public/ico.png"
          style="width: 30px; margin-right: 10px"
          alt=""
        /> -->
        {{ title }}
      </div>
      <div class="user">
        <img class="user-icon" :src="$store.state.user.headPic || defaultIcon" alt="">
        <span class="user-name">{{$store.state.user.nickName || '未登录'}}</span>
      </div>
    </div>
    <div class="video-container">
      <div v-if="isRefresh" class="video-wrap">
        <video
          ref="video_player"
          id="video_player"
          class="course-video"
          preload="auto"
          playsinline
          webkit-playsinline
        ></video>
      </div>
      <div class="video-slider">
        <div class="chapter-navtitle">课程目录</div>
        <div class="chapter-list">
          <template v-for="(item, index) in chapterList">
            <!-- :class="{ active: curId === item.id }" -->
            <div v-if="item.children.length > 0" :key="item.id">
              <div
                class="chapter-item chapter-subitem"
                @click="item.collpased = !item.collpased"
              >
                <!-- <span>{{ index + 1 }}.</span> -->
                <span class="chapter-num">{{ item.children.length }}节</span>
                <Icon :class="{'chapter-subarrow-forward':item.collpased}" class="chapter-subarrow" type="ios-arrow-down" />
                {{ item.name }}
              </div>
              <div :class="{'child-list-show': !item.collpased}" class="child-list">
                <div
                  v-for="(child, j) in item.children"
                  :key="index + '-' + j"
                  :class="{ active: curId === child.id }"
                  class="chapter-item chapter-child"
                  @click="handleClick(child)"
                >
                  <!-- <span>{{ j + 1 }}.</span> -->
                  {{ child.name }}
                </div>
              </div>
            </div>

            <div
              v-else
              :key="item.id"
              class="chapter-item"
              :class="{ active: curId === item.id }"
              @click="handleClick(item)"
            >
              <!-- <span>{{ index + 1 }}.</span> -->
              {{ item.name }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const SOURCE_TYPE = 1;
import defaultIcon from '@/assets/default_user.png'
export default {
  data() {
    return {
      isRefresh: true,
      id: "",
      curId: null,
      chapterList: [],
      title: "",
      defaultIcon
    };
  },
  created() {
    this.id = this.$route.query.id;
    if (!this.id) {
      return alert("未找到课程");
    }
  },
  mounted() {
    this.getCourseInfo();
  },
  beforeDestroy() {
    if (this.player) this.player.dispose();
    this.player = null;
  },
  methods: {
    handleClick(chapter) {
      if (this.curId === chapter.id) return;
      if (!chapter.resourceInfo) {
        return this.$Message.info("该课程未配置视频，请联系管理员");
      }
      if (this.player) {
        this.player.dispose();
        // 强制刷新组件
        this.isRefresh = false;
        this.$nextTick(() => {
          this.isRefresh = true;
          this.$nextTick(() => {
            this.changeVideo(chapter);
          });
        });
      } else {
        this.changeVideo(chapter);
      }
    },
    getCourseInfo() {
      this.$axios
        .get("/v1/course/getCourseInfo", {
          params: { courseId: this.id },
        })
        .then((res) => {
          if (res.code === 0) {
            const courseInfo = res.data;
            this.title = courseInfo.courseName;
            let first = null;
            const getChildChapter = (chapter) => {
              // assemblyId: 17
              // chapterId: 51
              // resourceCover: null
              // resourceId: "387702292835036272"
              // resourceType: 1
              // resourceUrl: "http://1306791500.vod2.myqcloud.com/627395e2vodcq1306791500/382a9071387702292835036272/bFJOsaoHBNwA.mp4"
              // sign: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwY2ZnIjoiYmFzaWNEcm1QcmVzZXQiLCJ1cmxBY2Nlc3NJbmZvIjp7InQiOiI2MjA2NDFhOCJ9LCJhcHBJZCI6MTMwNjc5MTUwMCwiZXhwaXJlVGltZVN0YW1wIjoxNjQ0NTc2ODkyLCJjdXJyZW50VGltZVN0YW1wIjoxNjQ0NTY5NjkyLCJmaWxlSWQiOiIzODc3MDIyOTI4MzUwMzYyNzIifQ.8Js6m0T5N_bUSzp3IEUl68ZIBFEoqfLfHEN_NvdhfLU"
              // title: "转码视频"
              const firstVideo = chapter.coursewareList.find(
                (item) => item.resourceType === SOURCE_TYPE
              );
              const chapterItem = {
                id: chapter.chapterId,
                name: chapter.chapterName,
                resourceInfo: firstVideo,
                children: [],
              };
              if (!first) first = chapterItem;
              return chapterItem;
            };
            this.chapterList = courseInfo.list.reduce(
              (chapterList, chapter) => {
                let obj;
                if (chapter.list.length > 0) {
                  obj = {
                    id: chapter.chapterId,
                    collpased: false,
                    name: chapter.chapterName,
                    children: chapter.list.map(getChildChapter),
                  };
                } else {
                  obj = getChildChapter(chapter);
                }
                chapterList.push(obj);
                console.log(chapterList);
                return chapterList;
              },
              []
            );
            if (first) this.changeVideo(first);
          } else {
            this.$Message.error(res.msg);
          }
        });
    },
    changeVideo(item) {
      console.log(item);
      this.curId = item.id;
      // eslint-disable-next-line no-undef
      // this.player = new TCPlayer("video_player", {
      //   // player-container-id 为播放器容器 ID，必须与 html 中一致
      //   fileID: "387702292835036272", // 请传入需要播放的视频 fileID（必须）
      //   appID: "1306791500", // 请传入点播账号的 appID（必须）
      //   //私有加密播放需填写 psign， psign 即超级播放器签名，签名介绍和生成方式参见链接：https://cloud.tencent.com/document/product/266/42436
      //   psign:
      //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6MTMwNjc5MTUwMCwiZmlsZUlkIjoiMzg3NzAyMjkyODM1MDM2MjcyIiwiY3VycmVudFRpbWVTdGFtcCI6MTYzOTU0OTY1NCwicGNmZyI6ImJhc2ljRHJtUHJlc2V0IiwidXJsQWNjZXNzSW5mbyI6e30sImRybUxpY2Vuc2VJbmZvIjp7fX0.Ej9AqAEc3FKcShhS9hSTGeUzhLbVGl3Ei9Q0RzDvahQ",
      // });

      const { resourceInfo = {} } = item;
      // eslint-disable-next-line no-undef
      this.player = new TCPlayer("video_player", {
        fileID: resourceInfo.resourceId,
        appID: "1306791500",
        psign: resourceInfo.sign,
      });
    },
  },
};
</script>

<style scoped>
.video-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.header {
  flex-shrink: 0;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: space-between;
}
.header .course-title {
  line-height: 60px;
  padding-left: 20px;
  display: flex;
  align-items: center;
}
.header .user{
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.header .user .user-icon{
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.header .user .user-name{
  font-size: 14px;
  color: #333;
}
.video-container {
  height: calc(100% - 60px);
  display: flex;
}
.video-wrap {
  width: 100%;
  height: 100%;
}
.course-video {
  width: 100%;
  height: 100%;
}
.video-slider {
  flex-shrink: 0;
  width: 30%;
  max-width: 500px;
  min-width: 300px;
  height: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 20px;
}
.chapter-list {
  /* padding: 0 10px; */
}
.child-list{
  max-height: 0;
  overflow: hidden;
  transition: all .2s;
}
.child-list-show {
  max-height: 2000px;
}
.chapter-navtitle {
  padding: 15px 20px 10px;
}
.chapter-item {
  min-height: 40px;
  line-height: 24px;
  padding: 10px;
  cursor: pointer;
  /* padding-left: 40px; */
  padding-left: 20px;
  border-top: 1px solid #eee;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  user-select: none;
}

.chapter-item.chapter-subitem {
  color: #666;
  /* background: #f3f3f3; */
  padding-right: 40px;
  cursor: default;
}

.chapter-subarrow{
  transition: all 0.2s;
}
.chapter-subarrow-forward{
  transform: rotate(-90deg);
}
.chapter-num {
  margin-right: -30px;
  float: right;
  font-size: 12px;
  color: #999;
}
/* .chapter-item span {
  display: inline-block;
  width: 24px;
  margin-left: -24px;
} */
.chapter-item:hover {
  background-color: rgba(137, 149, 171, 0.1);
}
.chapter-item.chapter-child {
  /* padding-left: 54px; */
  padding-left: 34px;
}
.chapter-item.active {
  /* background: rgba(137, 149, 171, 0.1); */
  /* background: #c14038; */
  color: #c14038;
}

@media screen and (max-width: 798px) {
  .video-container {
    height: 56.25vw !important;
    display: block;
  }
  .video-slider {
    width: 100% !important;
    max-width: 100%;
    overflow-y: initial;
  }
}
</style>