<!--
 * @Author: your name
 * @Date: 2022-01-17 10:39:48
 * @LastEditTime: 2022-04-19 21:27:57
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /web/src/views/About/index.vue
-->
<template>
  <div>
    <Header />
    <div class="w-1220">
      <div class="about-container">
        <h1>关于我们</h1>
        <div>
          <p>中华书局于1912年元月由陆费逵先生在上海创办。建局之初，奉行“开启民智”的宗旨，云集了一大批如梁启超、于右任、舒新城等专家学者及社会名流，陆续出版了《辞海》《古今图书集成》《新中华》等具有深远影响的辞书、古籍和刊物，享誉海内外。</p>
          <p>建国后中华书局出版了“二十四史”及《清史稿》点校本，被公认为新中国最伟大的古籍整理出版工程；还陆续规划出版了一批重点丛书，为学术研究、普及阅读提供大量优秀著作。</p>
          <p>中华书局百余年来不断守正出新，构建了结构完整、品种齐全、质量上乘的中华优秀传统文化产品体系。包括面向学术界的传统文化典籍整理和学术出版、面向全社会各类读者的中华优秀传统文化大众出版、面向青少年的中华优秀传统文化教育出版。 </p>
          <p>传统文化教材编辑部（暨经典教育研究中心）正是中华书局为了承续先辈“国之根本，在于教育”的信念和弘扬中华优秀传统文化的夙愿而成立的经典教育研究机构。十余年来，编辑部秉持“弘扬传统文化，服务教学实践，传承中华美德、培育民族精神”的理念，致力于经典教育的课程化、体验化和常识化。多年来，传统文化教材编辑部累计出版包括《中华文化基础教材》《新编中华文化基础教材》</p>
          <p>《中华优秀传统文化》系列教材在内的各类中小学经典教育教材9种共100多册，教师用书及与优秀传统文化主题相关的读本200多册，举办全国性的经典教育论坛十五届，培训经典教育师资近三万人，获得了教育部、各地市教育局和各级各类学校的认可和支持。</p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/layouts/Header'
import Footer from '@/components/layouts/Footer'

export default {
  name: 'About',
  components: {
    Header,
    Footer
  }
}
</script>

<style>
.about-container{
  background: #fff;
  margin: 32px 0;
  padding: 40px;
}
.about-container h1{
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}
.about-container p{
  line-height: 24px;
  margin-top: 1em;
  text-indent: 2em;
}
@media screen and (max-width: 798px) {
  .about-container {
    padding: 20px;
    margin: 10px 0;
  }
  .about-container h1{
    font-size: 18px;
  }
  .about-container p{
    font-size: 14px;
  }
}
</style>
