<template>
  <header>
    <div class="fixed-top">
      <div class="w-1220 content">
        <div class="logo">
          <img src="../../assets/logo.png" alt="" />
        </div>
        <div :class="['nav-wrap', collpase ? 'collpased' : '']">
          <div class="nav">
            <li
              v-for="item in nav"
              :key="item.name"
              @click="handleLink(item)"
              :class="{ active: item.routeName === $route.name }"
            >
              {{ item.name }}
            </li>
          </div>
        </div>
        <div class="login-data">
          <template v-if="$store.state.user.id">
            <Dropdown @on-click="onDownMenuClick">
              <div class="user-info">
                <img
                  :src="$store.state.user.headPic || defaultIcon"
                  class="user-icon"
                  alt="用户头像"
                />
                <span class="user-nick">
                  {{ $store.state.user.nickName }}
                  <Icon type="ios-arrow-down"></Icon>
                </span>
              </div>

              <DropdownMenu slot="list">
                <DropdownItem name="out">退出登录</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <!-- <div>{{ $store.state.user.nickName }}</div> -->
          </template>
          <template v-else>
            <div @click="login(0)" class="btn-main-small-outline btn-login">
              登录
            </div>
            <div @click="login(1)" class="btn-main-small">注册</div>
          </template>
          <div class="btn-menu">
            <Icon @click="handleMenu" type="md-menu" />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import defaultIcon from '@/assets/default_user.png'
export default {
  data() {
    return {
      collpase: true,
      defaultIcon,
      nav: [
        {
          name: "首页",
          routeName: "Home",
        },
        {
          name: "学习中心",
          routeName: "Courses",
        },
        {
          name: "关于我们",
          routeName: "About",
        },
      ],
    };
  },
  methods: {
    handleLink(item) {
      this.$router.push({
        name: item.routeName,
      });
      this.collpase = true;
    },
    handleMenu() {
      this.collpase = false;
    },
    login(type) {
      this.$store.dispatch("showLogin", type);
    },
    onDownMenuClick(key) {
      console.log(key);
      if (key === "out") {
        this.logout();
      }
    },
    logout() {
      this.$Message.success("已退出登录");
      localStorage.removeItem("_USER");
      this.$store.dispatch("updateUser", null);
    },
  },
};
</script>

<style>
header {
  height: 60px;
  background: #fff;
}
.fixed-top {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 9;
  height: 60px;
  background: #fff;
}
header .content {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  user-select: none;
}
header .logo {
  width: 102px;
}
header .logo img {
  width: 102px;
  height: 30px;
  display: block;
}

header .nav {
  display: flex;
}
header .nav li {
  color: #222;
  padding: 0 32px;
  cursor: pointer;
  transition: all 0.2s;
}
header .nav li:hover {
  color: #c14038;
}

header .nav li.active {
  color: #c14038;
}
header .login-data {
  display: flex;
}
header .login-data .btn-login {
  margin-right: 16px;
}
.btn-menu {
  display: none;
}
header .user-info {
  display: flex;
  align-items: center;
}
header .user-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #eee;
  margin-right: 10px;
}
header .user-nick {
  cursor: pointer;
  font-size: 14px;
}

@media screen and (max-width: 640px) {
  header {
    height: 60px;
  }
  .fixed-top {
    height: 60px;
  }
  .collpased {
    display: none;
  }
  .nav-wrap {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
  }
  header .btn-menu {
    display: inline-block;
    margin-left: 16px;
    font-size: 24px;
    color: #c14038;
    line-height: 30px;
  }
  header .nav {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: #fff;
    display: block;
    line-height: 50px;
  }
  header .nav li {
    border-top: 1px solid #fafafa;
  }
}
</style>